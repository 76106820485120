<template>
  <div class="ar-view">
<!--    <div class="ar-view__temp">
      <button type="button" v-on:click="showSuccessPopup = !showSuccessPopup">Du hast gewonnen!</button>
      <button type="button" v-on:click="showFailPopup = !showFailPopup">pas de&nbsp;gains</button>
    </div>-->
    <Tip v-if="inSphere && !prizeNotify" back="/" />

    <div class="ar-view__bg">
<!--      <img src="/images/room.jpg" srcset="/images/room@2x.jpg 2x" alt="">-->
    </div>
    <div class="ar-view__camera">
      <CameraControls :centerButton="centerButton"
                      :prizeNotify="this.prizeNotify"/>
    </div>
    <div class="ar-view__popup" v-if="showSuccessPopup">
      <Popup headline="Herzlichen Glückwunsch!
      Du hast gewonnen!" link-text="Dein Gewinn" link="/prize" :onClose="this.onPopupClose"/>
    </div>
    <div class="ar-view__popup" v-if="showFailPopup">
      <Popup description="Leider hat es dieses Mal nicht geklappt!
      Versuche es morgen noch einmal!" link-text="Mehr erfahren" link="/ar-experience" :onClose="this.onPopupClose"/>
    </div>
  </div>
</template>

<script>
import Tip from '@/components/Tip.vue'
import CameraControls from '@/components/CameraControls.vue'
import Popup from '@/components/Popup.vue'
export default {
  name: 'ARView',
  components: {
    Tip,
    CameraControls,
    Popup
  },
  data () {
    return {
      showSuccessPopup: false,
      showFailPopup: false,
      centerButton: 'record',
      prizeNotify: false,
      inSphere: true
    }
  },
  methods: {
    onPopupClose: function() {
      router.go(-1);
      //router.push({path: 'ar-experience'});
    }
  },
  mounted: async function() {
    store.set('sawPrize', true);

    this.scanSub0 = store.sub(['inSphere'], (inSphere) => {
      this.inSphere = inSphere;
    });
    this.scanSub = store.sub(['mapShown', 'centerButton', 'prizeNotify'], (mapShown, centerButton, prizeNotify) => {
      this.centerButton = centerButton;
      this.prizeNotify = prizeNotify;

      if(!prizeNotify){
        this.showFailPopup = true;
        this.showSuccessPopup = false;
      }else{
        this.showFailPopup = false;
        this.showSuccessPopup = true;
      }
    });

    store.set('currentPage', 'ArView');
    this.showFailPopup = false;
    this.showSuccessPopup = false;
    var winning;
    var oldWin = ARController.PrizeController.get();
    if(oldWin.win){
      winning = oldWin;
    }else{
      if( ARController.secret.secret === 'santa' ){
        winning = { win: true, id: 'tulen', prize: 666 }
      }else{
        winning = await Ajax.async.post( '/api/lottery', ARController.secret );
      }
      ARController.PrizeController.set(winning);
    }

    if(!winning.win){

      this.showFailPopup = true;
      this.showSuccessPopup = false;
    }else{
      this.showFailPopup = false;
      this.showSuccessPopup = true;
    }
  },
  unmounted(){
    this.scanSub && this.scanSub();
    this.scanSub0 && this.scanSub0();
  }
}
</script>

<style lang="scss">
.ar-view {
  position: relative;
  flex-grow: 1;
}

.ar-view__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ar-view__temp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
