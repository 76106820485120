<template>
  <div class="popup" v-if="thisShow">
    <button class="popup__close" v-on:click="onClose && onClose(); thisShow = !thisShow; " aria-label="fermer la fenêtre contextuelle">
      <CrossIcon />
    </button>
    <div class="popup__logo">
      <LogoIcon />
    </div>
    <p class="popup__headline" v-if="headline">{{ headline }}</p>
    <div class="popup__description" v-if="description">
      <p>{{ description }}</p>
    </div>
    <Button :to="link" :value="linkText"></Button>
  </div>
</template>

<script>
import CrossIcon from '@/assets/images/cross.svg?inline'
import LogoIcon from '@/assets/images/logo.svg?inline'
import Button from '@/components/Button.vue'
export default {
  name: 'Popup',
  props: {
    headline: {
      required: false,
      type: String
    },
    onClose: {
      required: false,
      type: Function
    },
    description: {
      required: false,
      type: String
    },
    link: {
      type: String
    },
    linkText: {
      type: String
    }
  },
  data () {
    return {
      thisShow: true
    }
  },
  components: {
    CrossIcon,
    LogoIcon,
    Button
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white;
  background-color: rgba($black, 0.7);
  z-index: 100;
}

.popup__close {
  position: absolute;
  top: 22px;
  right: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 30px;
  height: 30px;
  color: $white;
  background-color: transparent;
  border: 0;
  svg {
    display: block;
    width: 12px;
    height: 12px;
  }
}

.popup__logo {
  margin-bottom: 22px;
  width: 96px;
  height: 19px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.popup__headline {
  margin: 0 0 25px;
  text-transform: uppercase;
  font-family: $accent-font;
  font-weight: 500;
  font-size: 52px;
  line-height: 1.1384;
  letter-spacing: 0.2px;
  white-space: pre-line;
}

.popup__description {
  margin: 0 auto 25px;
  max-width: 280px;
  font-size: 14px;
  p {
    margin: 0 0 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.popup .button {
  min-width: 200px;
}
</style>
